<template>
  <div class="h-full">
    <v-card outlined class="h-full">
      <v-card-title>
        <div class="d-flex justify-space-between w-100">
          <span>Today's Scheduled Vehicles</span>
          <div class="d-flex align-center">
            <v-btn color="primary" @click="openWidget()" class="ml-auto mr-4">View Assignments</v-btn>
            <span class="s-number green">{{ scheduledAssignments.length }}</span>
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Trip #</th>
                <th class="text-left">Driver</th>
                <th class="text-left">Vehicle</th>
                <th class="text-left">Vehicle Location</th>
                <th class="text-left">Vehicle Pickup</th>
                <th class="text-left">Vehicle Return</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="a in scheduledAssignments"
                :key="a.id"
                @click="$router.push(`/trip-request/${a.tripRequestId}?assignment=true&dashboard=true`)"
              >
                <td>{{ a.tripRequestId }}</td>
                <td>{{ getDriver(a) }}</td>
                <td>{{ getVehicle(a) }}</td>
                <td>{{ getVehicleLocation(a.vehicleId) || '-' }}</td>
                <td>{{ getVehiclePickupDateTime(a) }}</td>
                <td>{{ getVehicleReturnDateTime(a) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <modal-assignment-list
      ref="schedule"
      name="Today's Assignments"
      :assignments="scheduledAssignments"
    ></modal-assignment-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { format } from 'date-fns';
import { todayString, getVehicleLocation } from '@/util';
import ModalAssignmentList from './ModalAssignmentList.vue';

export default {
  components: { ModalAssignmentList },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'schedule') this.openWidget();
  },
  data() {
    return { getVehicleLocation };
  },
  computed: {
    ...mapGetters('assignment', ['assignments']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('location', ['locations']),
    ...mapGetters('app', ['dashboardPanel']),
    scheduledAssignments() {
      return this.assignments
        .filter((e) => e.leaveDate == todayString())
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('schedule');
      this.$refs.schedule.dialog = true;
    },
    getDriver(item) {
      if (item.driver) return item.driver;
      else if (item.driverId)
        return `${this.driversById[item.driverId].firstName} ${this.driversById[item.driverId].lastName}`;
      else return '-';
    },
    getVehicle(item) {
      if (item.vehicle) return item.vehicle;
      else if (item.vehicleId) return this.vehiclesById[item.vehicleId].name;
      else return '-';
    },
    getDateTime(date, time) {
      const d = new Date(date);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      const s = time.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${format(dt, 'LLL dd')} @ ${hour}:${minute} ${ampm}`;
    },
    getVehiclePickupDateTime(item) {
      if (item.leg == 0) {
        return this.getDateTime(item.vehPickupDate, item.vehPickupTime);
      } else {
        return this.getDateTime(item.leaveDate, item.leaveTime);
      }
    },
    getVehicleReturnDateTime(item) {
      if (item.leg == 0) {
        return this.getDateTime(item.vehReturnDate, item.vehReturnTime);
      } else {
        return this.getDateTime(item.returnDate, item.returnTime);
      }
    },
  },
};
</script>

<style scoped>
.s-number {
  color: white;
  margin-left: auto;
  width: 32px;
  border-radius: 50%;
  text-align: center;
}
.w-100 {
  width: 100%;
}
</style>
