<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Waiting for My Approval
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ awaitingApproval.length }}</span>
      </v-card-title>
      <v-card-text>
        You have {{ awaitingApproval.length }} {{ awaitingApproval.length != 1 ? 'trips' : 'trip' }} that are waiting
        for your approval
      </v-card-text>
    </v-card>

    <modal-trip-list ref="approval" name="Waiting for My Approval" :trips="awaitingApproval"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'approval') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.awaitingApproval.length === 0 ? 'green' : 'red';
    },
    awaitingApproval() {
      return this.tripRequests
        .filter(
          (e) =>
            e.approval &&
            e.approval.awaitingApproval &&
            e.approval.awaitingApproval.primaryApprovers.map((e) => e.userEmail).includes(this.me.email)
        )
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('approval');
      this.$refs.approval.dialog = true;
    },
  },
};
</script>
