<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Third Party Payment
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ thirdPartyPayment.length }}</span>
      </v-card-title>
      <v-card-text>
        There {{ thirdPartyPayment.length != 1 ? 'are' : 'is' }} {{ thirdPartyPayment.length }}
        {{ thirdPartyPayment.length != 1 ? 'trips' : 'trip' }} that
        {{ thirdPartyPayment.length != 1 ? 'have' : 'has' }} indicated that funds are payable to a third party
      </v-card-text>
    </v-card>

    <modal-trip-list ref="thirdPartyPayment" name="Third Party Payment" :trips="thirdPartyPayment"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';
import { todayString } from '@/util';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'thirdPartyPayment') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.thirdPartyPayment.length === 0 ? 'green' : 'red';
    },
    thirdPartyPayment() {
      return this.tripRequests
        .filter((e) => e.status == 1 && e.payableToThirdParty && new Date(e.leaveDate) >= new Date(todayString()))
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('thirdPartyPayment');
      this.$refs.thirdPartyPayment.dialog = true;
    },
  },
};
</script>
