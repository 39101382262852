<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Changes Requested
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ changesRequested.length }}</span>
      </v-card-title>

      <v-card-text>
        {{ isAdmin ? `There ${changesRequested.length != 1 ? 'are' : 'is'}` : 'You have submitted' }}
        {{ changesRequested.length }} {{ changesRequested.length != 1 ? 'trips' : 'trip' }} that
        {{ changesRequested.length != 1 ? 'have' : 'has' }} had changes requested before
        {{ changesRequested.length != 1 ? 'they' : 'it' }} can be approved
      </v-card-text>
    </v-card>

    <modal-trip-list ref="changesRequested" name="Changes Requested" :trips="changesRequested"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'changesRequested') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.changesRequested.length === 0 ? 'green' : 'red';
    },
    isAdmin() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
    },
    changesRequested() {
      let trips = [];
      if (this.isAdmin)
        trips = this.tripRequests
          .filter((e) => e.status == -1 && e.submittedUser)
          .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
      else
        trips = this.tripRequests
          .filter((e) => e.status == -1 && e.submittedUser && e.submittedUser == this.me.id)
          .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
      return trips;
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('changesRequested');
      this.$refs.changesRequested.dialog = true;
    },
  },
};
</script>
