<template>
  <div>
    <Dashboard />

    <view-available-vehicles ref="fav"></view-available-vehicles>
  </div>
</template>

<script>
import Dashboard from './Dashboard.vue';
import ViewAvailableVehicles from '@/components/ViewAvailableVehicles.vue';

export default {
  inject: ['eventHub'],
  components: { Dashboard, ViewAvailableVehicles },
  async created() {
    this.eventHub.$on('findVehiclesRequestedForDashboard', () => this.showFindVehicles());
  },
  beforeDestroy() {
    this.eventHub.$off('findVehiclesRequestedForDashboard');
  },
  computed: {},
  methods: {
    showFindVehicles() {
      this.$refs.fav.dialog = true;
    },
  },
};
</script>
