<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title>
        Invoices Approved for Payment <span class="number green">{{ invoiceCount }}</span>
      </v-card-title>
      <v-card-text>You have {{ invoiceCount }} invoice(s) approved for payment</v-card-text>
    </v-card>

    <modal-invoice-list title="Approved Payments Invoices" ref="approved" :options="options"></modal-invoice-list>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ModalInvoiceList from './ModalInvoiceList.vue';

export default {
  components: {
    ModalInvoiceList,
  },
  data() {
    return {
      invoiceCount: 0,
      options: { filter: { status: 'approved' }, page: { pageSize: 0 }, count: 1 },
    };
  },
  computed: {
    ...mapGetters('app', ['dashboardInvoicePanel']),
  },
  methods: {
    ...mapActions('invoice', ['queryInvoices']),
    ...mapMutations('app', ['setDashboardInvoicePanel']),
    openWidget() {
      this.setDashboardInvoicePanel('approved');
      this.$refs.approved.dialog = true;
    },
  },
  mounted() {
    this.queryInvoices({ options: this.options }).then((response) => {
      this.invoiceCount = response ? response.data : 0;
    });

    if (this.setDashboardInvoicePanel == 'approved') this.openWidget();
  },
};
</script>
