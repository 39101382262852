<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Upcoming Assignments
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number green">{{ upcomingAssignments.length }}</span>
      </v-card-title>

      <v-card-text>
        Your location(s) have {{ upcomingAssignments.length }} upcoming
        {{ upcomingAssignments.length != 1 ? 'assignments' : 'assignment' }}
      </v-card-text>
    </v-card>

    <modal-assignment-list
      ref="upcomingAssignments"
      name="Upcoming Assignments"
      :assignments="upcomingAssignments"
    ></modal-assignment-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalAssignmentList from './ModalAssignmentList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalAssignmentList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'upcomingAssignments') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('assignment', { assignments: 'assignments', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    upcomingAssignments() {
      return this.assignments
        .filter((e) => new Date(e.leaveDate) >= new Date(todayString()))
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('upcomingAssignments');
      this.$refs.upcomingAssignments.dialog = true;
    },
  },
};
</script>
