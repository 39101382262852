<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Need Attention
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ needAttention.length }}</span>
      </v-card-title>
      <v-card-text>
        <p>
          You have {{ needAttention.length }} {{ needAttention.length != 1 ? 'trips' : 'trip' }} upcoming in the next 5
          days that aren't approved and/or need assignments.
        </p>
        <p v-if="tbdTrips.length">
          The leave/return date/time for {{ tbdTrips.length }} {{ tbdTrips.length != 1 ? 'trips' : 'trip' }} in the next
          5 days is still marked TBD.
        </p>
      </v-card-text>
    </v-card>

    <modal-trip-list ref="attention" name="Need Attention" :trips="needAttention"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'attention') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.needAttention.length === 0 ? 'green' : 'red';
    },
    needAttention() {
      return this.tripRequests
        .filter((e) => e.attention && new Date(e.leaveDate) >= new Date(todayString()))
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
    tbdTrips() {
      return this.needAttention.filter((e) => e.actualTimeTBD);
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('attention');
      this.$refs.attention.dialog = true;
    },
  },
};
</script>
