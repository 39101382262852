<template>
  <v-toolbar-items>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="operations.length" color="#000" text v-bind="attrs" v-on="on" class="text-capitalize">
          Operations <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <template v-for="(operation, index) in operations">
          <v-list-item v-if="operation.show" :key="index" @click="operation.onClick">
            <v-list-item-title>{{ operation.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AssignmentListMenu',
  inject: ['eventHub'],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['me']),
    operations() {
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner
      )
        return [{ name: 'Find Available Vehicles', onClick: this.findVehicles, show: true }];
      else return [];
    },
  },
  created() {},
  beforeDestroy() {},
  methods: {
    findVehicles() {
      this.eventHub.$emit('findVehiclesRequestedForDashboard');
    },
    onMenuItemClick(item) {
      if (item.onClick) item.onClick();
    },
  },
  watch: {},
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
</style>
