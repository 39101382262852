<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Upcoming Trips
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number green">{{ upcomingTrips.length }}</span>
      </v-card-title>
      <v-card-text>
        Your location(s) have {{ upcomingTrips.length }} upcoming {{ upcomingTrips.length != 1 ? 'trips' : 'trip' }}
      </v-card-text>
    </v-card>

    <modal-trip-list ref="upcomingTrips" name="Upcoming Trips" :trips="upcomingTrips"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'upcomingTrips') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    upcomingTrips() {
      return this.tripRequests
        .filter((e) => new Date(e.leaveDate) >= new Date(todayString()) && e.status > -3)
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('upcomingTrips');
      this.$refs.upcomingTrips.dialog = true;
    },
  },
};
</script>
