<template>
  <div class="h-full">
    <v-card outlined class="h-full">
      <v-card-title class="relative">
        Trips w/ Students Away for Lunch
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ awayForLunch.length }}</span>
      </v-card-title>
      <v-card-text>
        <p>
          There {{ awayForLunch.length != 1 ? 'are' : 'is' }} {{ awayForLunch.length }} upcoming
          {{ awayForLunch.length != 1 ? 'trips' : 'trip' }} where the submitter has indicated that students will be away
          <v-btn outlined small text @click="openWidget('awayForLunch')">View Trips</v-btn>
        </p>
        <p>
          There {{ needLunch.length != 1 ? 'are' : 'is' }} {{ needLunch.length }} upcoming
          {{ needLunch.length != 1 ? 'trips' : 'trip' }} where the submitter has indicated that students will need
          packed lunches
          <v-btn outlined small text @click="openWidget('needLunch')">View Trips</v-btn>
        </p>
      </v-card-text>
    </v-card>

    <modal-trip-list ref="lunch" name="Trips w/ Students Away for Lunch" :trips="trips"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'lunch') this.openWidget();
  },
  data() {
    return {
      trips: [],
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.awayForLunch.length === 0 ? 'green' : 'amber';
    },
    awayForLunch() {
      return this.tripRequests
        .filter((e) => e.awayForLunch && new Date(e.leaveDate) >= new Date(todayString()) && e.status > -3)
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
    needLunch() {
      return this.awayForLunch.filter((e) => e.needLunch);
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget(which) {
      if (which == 'needLunch') this.trips = this.needLunch;
      else this.trips = this.awayForLunch;
      this.setDashboardPanel('lunch');
      this.$refs.lunch.dialog = true;
    },
  },
};
</script>
