<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        My Upcoming Trips
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number green">{{ myTrips.length }}</span>
      </v-card-title>
      <v-card-text>
        {{ myTrips.length }} {{ myTrips.length != 1 ? 'trips' : 'trip' }} that you've submitted
        {{ myTrips.length != 1 ? 'are' : 'is' }} still upcoming
      </v-card-text>
    </v-card>

    <modal-trip-list ref="my" name="My Upcoming Trips" :trips="myTrips"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'my') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    myTrips() {
      return this.tripRequests
        .filter(
          (e) => e.submittedUser == this.me.id && new Date(e.leaveDate) >= new Date(todayString()) && e.status > -3
        )
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('my');
      this.$refs.my.dialog = true;
    },
  },
};
</script>
