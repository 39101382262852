<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Overnight / OOS Trips
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ overnightOOS.length }}</span>
      </v-card-title>
      <v-card-text>
        You have {{ overnightOOS.length }} upcoming Overnight / Out of State
        {{ overnightOOS.length != 1 ? 'trips' : 'trip' }}
      </v-card-text>
    </v-card>

    <modal-trip-list ref="oos" name="Overnight / OOS Trips" :trips="overnightOOS"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'oos') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.overnightOOS.length === 0 ? 'green' : 'amber';
    },
    overnightOOS() {
      return this.tripRequests
        .filter((e) => e.outOfState && new Date(e.leaveDate) >= new Date(todayString()) && e.status > -3)
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('oos');
      this.$refs.oos.dialog = true;
    },
  },
};
</script>
