<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card class="blue-grey lighten-5">
      <v-toolbar dark color="primary" class="mb-2">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>

      <invoice-dashboard :options="options" :hide-sub-menu="true"></invoice-dashboard>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

import InvoiceDashboard from '@/views/Invoices/InvoiceDashboard.vue';

export default {
  components: { InvoiceDashboard },
  name: 'ModalInvoiceList',
  props: {
    title: {
      type: String,
      default: 'Invoices',
    },
    options: {
      type: Object,
      default: () => ({
        filters: { status: 'approved' },
        page: {
          currentPage: 1,
          pageSize: 20,
        },
      }),
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapMutations('app', ['setDashboardInvoicePanel']),
    close() {
      this.setDashboardInvoicePanel(null);
      this.dialog = false;
    },
  },
};
</script>
