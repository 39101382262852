<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Trips w/ Health Concerns
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ healthConcerns.length }}</span>
      </v-card-title>
      <v-card-text>
        There {{ healthConcerns.length != 1 ? 'are' : 'is' }} {{ healthConcerns.length }} upcoming
        {{ healthConcerns.length != 1 ? 'trips' : 'trip' }} where the submitter has indicated that students attending
        may have health concerns
      </v-card-text>
    </v-card>

    <modal-trip-list ref="health" name="Trips w/ Health Concerns" :trips="healthConcerns"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'health') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.healthConcerns.length === 0 ? 'green' : 'amber';
    },
    healthConcerns() {
      return this.tripRequests
        .filter((e) => e.haveHealthConcerns && new Date(e.leaveDate) >= new Date(todayString()) && e.status > -3)
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('health');
      this.$refs.health.dialog = true;
    },
  },
};
</script>
