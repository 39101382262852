<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card class="blue-grey lighten-5">
      <v-toolbar dark color="primary" class="mb-4">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>{{ name }}</v-toolbar-title>
      </v-toolbar>

      <v-row dense class="px-8 align-start" no-gutters>
        <v-chip label class="mx-2 button-chip">
          {{ sortableAssignments.length }} Assignment{{ sortableAssignments.length != 1 ? 's' : '' }}
        </v-chip>

        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn class="text-capitalize" text color="#000" v-on="on">
              Sort
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(s, i) in sortOptions" :key="i" @click="setCurrentSort(i)">
              <v-list-item-title>
                {{ s.label }}
                <v-icon v-if="!!s.order">{{ s.order == 'desc' ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>

        <v-tooltip
          v-if="notifyConfig.driverDecline || notifyConfig.driverAccept"
          bottom
          contained
          color="#fff"
          class="m-0 mt-4"
        >
          <template v-slot:activator="{ on, attrs }">
            <small>
              <v-chip v-bind="attrs" rounded outlined v-on="on" class="button-chip">
                <v-icon class="mr-2" color="blue">mdi-information</v-icon>Color Codes
              </v-chip>
            </small>
          </template>

          <div class="mx-2">
            <div class="font-weight-bold green--text text--darken-1">Driver Accepted</div>
            <div class="font-weight-bold blue--text text--darken-2">Driver Assigned (Pending Action)</div>
            <div class="font-weight-bold red--text text-accent-2">Driver Declined</div>
          </div>
        </v-tooltip>

        <v-switch class="ma-0 pa-0 mx-4 mt-2" v-model="allExpanded" label="Expand All" hide-details></v-switch>
      </v-row>

      <v-row justify="center" dense class="mt-4 px-8 pb-16" no-gutters>
        <v-col class="mt-2" cols="12" md="12" v-for="(a, i) of sortableAssignments" :key="a.id">
          <assignment
            :assignmentProp="a"
            :allExpanded="allExpanded"
            :groupNext="
              i + 1 < sortableAssignments.length && sortableAssignments[i + 1].tripRequestId == a.tripRequestId
            "
            returnToDashboard
          ></assignment>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Assignment from '@/views/Assignments/Assignment.vue';

export default {
  components: { Assignment },
  props: {
    name: String,
    assignments: Array,
  },
  data() {
    return {
      dialog: false,
      allExpanded: false,
      sortableAssignments: [],
      sortOptions: [
        { label: 'Depart Date', sortBy: 'leaveDate' },
        { label: 'Trip #', sortBy: 'tripRequestId' },
        { label: 'Location', sortBy: 'locationName' },
        { label: 'Destination', sortBy: 'destinationName' },
        { label: 'Vehicle Owner', sortBy: 'vehicleOwner' },
        { label: 'Vehicle', sortBy: 'vehicleNumber' },
        { label: 'Vehicle Type', sortBy: 'vehicleType' },
      ],
      currentSort: 0,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['notifyConfig']),
    ...mapGetters('app', ['assignmentListSort']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
  },
  mounted() {
    this.setCurrentSort(this.assignmentListSort.index, true);
    this.sortableAssignments = [...this.assignments];
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel', 'setAssignmentListSort']),
    setCurrentSort(index, maintain) {
      this.currentSort = index;
      this.sortAssignments(maintain);
    },
    sortAssignments(maintain) {
      const option = this.sortOptions[this.currentSort];
      this.sortOptions.forEach((e) => (e.order = null));
      option.order = this.assignmentListSort.index == this.currentSort ? this.assignmentListSort.order : 'asc';
      if (!maintain) option.order = option.order == 'desc' ? 'asc' : 'desc';

      if (option.sortBy == 'locationName')
        this.sortableAssignments.forEach((e) => (e.locationName = this.locationsById[e.locationId].name));
      if (option.sortBy == 'destinationName')
        this.sortableAssignments.forEach((e) => (e.destinationName = this.destinationsById[e.destinationId].name));
      if (option.sortBy == 'vehicleOwner')
        this.sortableAssignments.forEach((e) => {
          const vo = this.vehicleOwners.find((v) => v.locationId == e.assignmentLocationId);
          e.vehicleOwner = vo ? vo.displayName || vo.userEmail : '';
        });
      if (option.sortBy == 'vehicleType')
        this.sortableAssignments.forEach((e) => (e.vehicleType = this.vehicleTypesById[e.vehicleTypeId].name));
      if (option.sortBy == 'vehicleNumber')
        this.sortableAssignments.forEach((e) => {
          e.vehicleNumber = e.vehicle || e.vehicleId ? e.vehicle || this.vehiclesById[e.vehicleId].name : '';
        });

      if (option.order == 'desc')
        this.sortableAssignments.sort((a, b) => {
          return option.sortBy == 'leaveDate' ||
            option.sortBy == 'locationName' ||
            option.sortBy == 'destinationName' ||
            option.sortBy == 'vehicleOwner' ||
            option.sortBy == 'vehicleNumber' ||
            option.sortBy == 'vehicleType'
            ? b[option.sortBy].localeCompare(a[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
            : b[option.sortBy] - a[option.sortBy];
        });
      else
        this.sortableAssignments.sort((a, b) => {
          return option.sortBy == 'leaveDate' ||
            option.sortBy == 'locationName' ||
            option.sortBy == 'destinationName' ||
            option.sortBy == 'vehicleOwner' ||
            option.sortBy == 'vehicleNumber' ||
            option.sortBy == 'vehicleType'
            ? a[option.sortBy].localeCompare(b[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
            : a[option.sortBy] - b[option.sortBy];
        });

      this.setAssignmentListSort({ index: this.currentSort, order: option.order });
    },
    close() {
      this.setDashboardPanel(null);
      this.dialog = false;
    },
  },
  watch: {
    assignments: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.sortableAssignments = [...value];
      },
    },
  },
};
</script>

<style scoped>
::v-deep .v-input--switch {
  margin-top: 0px;
}
.button-chip {
  height: 36px !important;
  font-size: 16px !important;
}
</style>
