<template>
  <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card class="blue-grey lighten-5">
        <v-toolbar dark color="primary" class="mb-6">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>{{ name }}</v-toolbar-title>
        </v-toolbar>

        <v-row dense class="px-8 align-start" no-gutters>
          <v-chip label class="mx-2 button-chip">{{ trips.length }} Trip{{ trips.length != 1 ? 's' : '' }}</v-chip>

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn class="text-capitalize" text color="#000" v-on="on">
                Sort
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(s, i) in sortOptions" :key="i" @click="setCurrentSort(i)">
                <v-list-item-title>{{ s.label }}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon v-if="currentSort === i">
                    {{ s.order == 'desc' ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <v-tooltip bottom contained color="#fff" class="ma-0 pa-0" :nudge-top="-10" tooltip-opacity="1">
            <template v-slot:activator="{ on, attrs }">
              <small>
                <v-chip class="mx-2 button-chip" rounded outlined v-bind="attrs" v-on="on">
                  <v-icon class="mr-2" color="blue">mdi-information</v-icon>Color Codes
                </v-chip>
              </small>
            </template>

            <div class="mx-2">
              <p class="font-weight-bold green--text text--darken-1">Approved & Assigned</p>
              <p class="font-weight-bold blue--text text--darken-2">Approved</p>
              <p class="font-weight-bold orange--text text--darken-3">Pending Approval</p>
              <p class="font-weight-bold amber--text text--darken-1">
                Waiting on Quote/Changes Requested (Not Submitted)
              </p>
              <p class="font-weight-bold red--text text-accent-2">Canceled/Denied</p>
            </div>
          </v-tooltip>

          <v-switch class="ma-0 pa-0 mx-4 mt-2" v-model="allExpanded" label="Expand All" hide-details></v-switch>
        </v-row>

        <v-row justify="center" dense class="mt-4 px-8 pb-16" no-gutters>
          <v-col class="mt-2" cols="12" md="12" v-for="tr of sortableTrips" :key="tr.id">
            <trip-request
              :tripRequest="tr"
              :tab="tab"
              returnToDashboard
              :allExpanded="allExpanded"
              @showHistory="showHistory"
              @cancel="cancel"
              @reschedule="reschedule"
              @showChangeSubmitter="showChangeSubmitter"
            ></trip-request>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <approval-history ref="history" :tripRequest="selectedItem"></approval-history>

    <cancel-trip ref="cancel" :tripRequest="selectedItem" @refreshTripRequests="refreshTripRequests"></cancel-trip>

    <reschedule-trip
      ref="reschedule"
      :tripRequest="selectedItem"
      @refreshTripRequests="refreshTripRequests"
    ></reschedule-trip>

    <change-submitter
      ref="changeSubmitter"
      :tripRequest="selectedItem"
      @refreshTripRequests="refreshTripRequests"
    ></change-submitter>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import TripRequest from '@/components/TripRequest.vue';
import ApprovalHistory from '../../Trips/HistoryModal.vue';
import CancelTrip from '@/components/CancelTrip.vue';
import RescheduleTrip from '@/components/Reschedule.vue';
import ChangeSubmitter from '../../Trips/ChangeSubmitter.vue';

export default {
  components: { TripRequest, ApprovalHistory, CancelTrip, RescheduleTrip, ChangeSubmitter },
  props: {
    name: String,
    trips: Array,
    tab: String,
  },
  data() {
    return {
      dialog: false,
      selectedItem: {},
      sortOptions: [
        { label: 'Pickup Date', sortBy: 'leaveDate' },
        { label: 'Trip #', sortBy: 'id' },
        { label: 'Location', sortBy: 'locationName' },
        { label: 'Destination', sortBy: 'destinationName' },
        { label: 'Submitter', sortBy: 'submittedUser' },
        { label: 'Vehicle Type', sortBy: 'vehicleTypeId' },
        { label: 'Date Submitted', sortBy: 'submittedTimestamp' },
      ],
      currentSort: 0,
      allExpanded: false,
      sortableTrips: [],
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('app', ['tripRequestListSort']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
  },
  mounted() {
    this.setCurrentSort(this.tripRequestListSort.index, true);
    this.sortableTrips = [...this.trips];
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    ...mapMutations('app', ['setTripRequestListSort']),
    setCurrentSort(index, maintain) {
      this.currentSort = index;
      this.sortRequests(maintain);
    },
    sortRequests(maintain) {
      const option = this.sortOptions[this.currentSort];
      this.sortOptions.forEach((e) => (e.order = null));
      option.order = this.tripRequestListSort.index == this.currentSort ? this.tripRequestListSort.order : 'asc';
      if (!maintain) option.order = option.order == 'desc' ? 'asc' : 'desc';

      if (option.sortBy == 'locationName')
        this.sortableTrips.forEach((e) => (e.locationName = this.locationsById[e.locationId].name));

      if (option.sortBy == 'destinationName')
        this.sortableTrips.forEach((e) => (e.destinationName = this.destinationsById[e.destinationId].name));

      if (option.order == 'desc')
        this.sortableTrips.sort((a, b) => {
          return option.sortBy == 'leaveDate' || option.sortBy == 'locationName' || option.sortBy == 'destinationName'
            ? b[option.sortBy].localeCompare(a[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
            : b[option.sortBy] - a[option.sortBy];
        });
      else
        this.sortableTrips.sort((a, b) => {
          return option.sortBy == 'leaveDate' || option.sortBy == 'locationName' || option.sortBy == 'destinationName'
            ? a[option.sortBy].localeCompare(b[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
            : a[option.sortBy] - b[option.sortBy];
        });

      this.setTripRequestListSort({ index: this.currentSort, order: option.order });
    },
    refreshTripRequests() {},
    showHistory(id) {
      this.selectedItem = this.tripRequests.find((e) => e.id == id);
      this.$refs.history.dialog = true;
    },
    cancel(id) {
      this.selectedItem = this.tripRequests.find((e) => e.id == id);
      this.$refs.cancel.dialog = true;
    },
    reschedule(id) {
      this.selectedItem = this.tripRequests.find((e) => e.id == id);
      this.$refs.reschedule.dialog = true;
    },
    showChangeSubmitter(id) {
      if (id) this.selectedItem = this.tripRequests.find((e) => e.id == id);
      else this.selectedItem = {};
      this.$refs.changeSubmitter.dialog = true;
    },
    close() {
      this.setDashboardPanel(null);
      this.dialog = false;
    },
  },
  watch: {
    trips(value) {
      this.sortableTrips = [...value];
    },
  },
};
</script>

<style scoped>
::v-deep .v-input--switch {
  margin-top: 0px;
}
.button-chip {
  height: 36px !important;
  font-size: 16px !important;
}
</style>
