<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title>
        Previously Approved Trips <span class="number green">{{ approved.length }}</span>
      </v-card-title>
      <v-card-text>
        You have {{ approved.length }} upcoming {{ approved.length != 1 ? 'trips' : 'trip' }} that you have approved
      </v-card-text>
    </v-card>

    <modal-trip-list ref="approved" name="Previously Approved Trips" :trips="approved"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString } from '@/util';
import ModalTripList from './ModalTripList.vue';

export default {
  components: { ModalTripList },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'approved') this.openWidget();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('app', ['dashboardPanel']),
    approved() {
      return this.tripRequests
        .filter(
          (e) =>
            e.approval &&
            e.approval.requiredApprovalLevels &&
            e.approval.requiredApprovalLevels
              .map((e) => e.primaryApprovers.map((a) => a.userEmail))
              .flat()
              .includes(this.me.email) &&
            (e.approval.awaitingApproval
              ? !e.approval.awaitingApproval.primaryApprovers.map((e) => e.userEmail).includes(this.me.email)
              : true) &&
            new Date(e.leaveDate) >= new Date(todayString())
        )
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('approved');
      this.$refs.approved.dialog = true;
    },
  },
};
</script>

<style scoped>
.h-full {
  height: 100%;
}
.number {
  color: white;
  margin-left: auto;
  width: 32px;
  border-radius: 50%;
  text-align: center;
}
</style>
