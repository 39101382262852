<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Recently Completed
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number green">{{ recentlyCompleted.length }}</span>
      </v-card-title>
      <v-card-text>
        There {{ recentlyCompleted.length != 1 ? 'are' : 'is' }} {{ recentlyCompleted.length }}
        {{ recentlyCompleted.length != 1 ? 'assignments' : 'assignment' }} from recently completed trips that need times
        and/or mileage entered
      </v-card-text>
    </v-card>

    <modal-assignment-list
      ref="recentlyCompleted"
      name="Recently Completed"
      :assignments="recentlyCompleted"
    ></modal-assignment-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { sub } from 'date-fns';
import { todayString } from '@/util';
import ModalAssignmentList from './ModalAssignmentList.vue';
import Spinner from '@/components/shared/Spinner.vue';

export default {
  components: { ModalAssignmentList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'recentlyCompleted') this.openWidget();
  },
  computed: {
    ...mapGetters('app', ['dashboardPanel']),
    ...mapGetters('user', ['me']),
    ...mapGetters('assignment', ['assignments']),
    ...mapGetters('tripRequest', { tripRequestsById: 'tripRequestsById', loading: 'isLoading' }),
    recentlyCompleted() {
      return this.assignments
        .filter(
          (e) =>
            (this.tripRequestsById[e.tripRequestId]?.approved ||
              this.tripRequestsById[e.tripRequestId]?.approval.approved) &&
            (!e.startTime || !e.endTime || !e.startMileage || !e.endMileage) &&
            new Date(e.leaveDate) <= new Date(todayString()) &&
            new Date(e.leaveDate) >= sub(new Date(todayString()), { days: 7 })
        )
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    openWidget() {
      this.setDashboardPanel('recentlyCompleted');
      this.$refs.recentlyCompleted.dialog = true;
    },
  },
};
</script>
